<template>
	<div
		id="c-long-read-desktop"
		:class="[
			'c-long-read-desktop',
			'transform w-fit max-w-full',
			'duration-200 ease-smooth-out',
		]"
		tabindex="-1"
	>
		<!-- Header -->
		<div
			:class="[
				'c-long-read-desktop__header',
				'border-b border-primary-button h-md',
				'text-h8 text-primary-button uppercase',
			]"
			v-text="`Sidens indhold:`"
		></div>

		<!-- Content -->
		<div
			ref="scroller"
			:class="[
				'c-long-read-desktop__content',
				'overflow-y-auto u-hide-scrollbar w-fit max-w-full pt-sm',
			]"
			@scroll="onInternalScroll"
		>
			<ul v-if="data.targets.length" ref="inner" class="space-y-xs">
				<li v-for="target in data.targets" :key="`target-${target.id}`">
					<a
						:href="`#${target.id}`"
						:tabindex="hidden ? -1 : 0"
						:class="[
							'c-long-read__target',
							'block relative',
							'text-left font-semibold',
							'duration-300 ease-smooth-out',

							{
								'text-body pr-sm hover:text-primary-button':
									!data.activeTarget ||
									data.activeTarget.id !== target.id,
								'c-long-read__target--active text-primary-button pl-sm':
									data.activeTarget &&
									data.activeTarget.id === target.id,
							},
						]"
						@click.prevent="
							() =>
								actions.scrollToTarget &&
								actions.scrollToTarget(target)
						"
						v-text="target.title"
					></a>
				</li>
			</ul>
		</div>

		<!-- Gradients -->
		<div
			:class="[
				'c-long-read-desktop__gradient',
				'c-long-read-desktop__gradient--top',
				'absolute top-md z-50',
				'pointer-events-none w-full h-xl z-50',
				'duration-300 ease-smooth-out',

				{
					'opacity-0': !canScrollUp,
				},
			]"
		></div>

		<div
			:class="[
				'c-long-read-desktop__gradient',
				'c-long-read-desktop__gradient--bottom',
				'absolute bottom-0 z-50',
				'pointer-events-none w-full h-xl',
				'duration-300 ease-smooth-out',

				{
					'opacity-0': !canScrollDown,
				},
			]"
		></div>
	</div>
</template>

<script>
export default {
	name: 'LongReadDesktop',

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		actions: {
			type: Object,
			default: () => ({}),
		},
		hidden: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			canScrollUp: true,
			canScrollDown: true,
		};
	},

	watch: {
		'data.targets'() {
			this.onInternalScroll({
				target: this.$refs?.scroller,
			});
		},
	},

	methods: {
		onInternalScroll({ target }) {
			this.canScrollUp = target.scrollTop > 0;

			if (this.$refs?.inner) {
				const { inner } = this.$refs;
				const { height: outerHeight } = target.getBoundingClientRect();
				const { height: innerHeight } = inner.getBoundingClientRect();
				this.canScrollDown =
					target.scrollTop < innerHeight - outerHeight;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-long-read-desktop {
	max-height: calc(100vh - 2 * var(--theme-spacing-lg));
}

.c-long-read-desktop__content {
	max-height: calc(
		100vh - 2 * var(--theme-spacing-lg) - var(--theme-spacing-md)
	);
}

.c-long-read-desktop__gradient--top {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	);
}

.c-long-read-desktop__gradient--bottom {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 100%
	);
}

.c-long-read__target:before {
	content: '';
	@apply absolute -left-8;
	@apply transform -translate-y-1/2;
	@apply w-6 h-6 rounded-full bg-primary-button;
	@apply duration-300 ease-smooth-out;
	@apply text-text;
	top: calc(0.85em - 3px);
}

.c-long-read__target--active:before {
	@apply text-primary-button opacity-100 left-8;
}

.t-long-read__targets-enter-active,
.t-long-read__targets-leave-active {
	@apply duration-300 ease-smooth-out;
}
.t-long-read__targets-enter,
.t-long-read__targets-leave-to {
	@apply opacity-0;
}
</style>
