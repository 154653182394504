<template>
	<div v-if="blocks && blocks.length" class="c-block-list">
		<template v-for="block in blocks">
			<Component
				:is="getBlockNameFromAlias(block.alias)"
				v-if="blockExists(block.alias)"
				:id="block.key"
				:key="block.key"
				class="c-block-list__block print:w-full"
				v-bind="block.content"
			/>

			<BlockListPlaceholder
				v-else-if="isDevelopment"
				:key="block.key"
				class="c-block-list__block print:w-full"
				v-bind="block"
			/>
		</template>
	</div>
</template>

<script>
import BlockListPlaceholder from './blocks/BlockListPlaceholder.vue';

import {
	getBlockNameFromAlias,
	getBlocksBySolution,
} from '~/assets/js/block-lists';

const blocks = getBlocksBySolution('main');
const blockNames = Object.keys(blocks);

export default {
	name: 'MainBlockList',

	components: {
		BlockListPlaceholder,
		...blocks,
	},

	props: {
		blockList: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			isDevelopment: process.env.NODE_ENV === 'development',
		};
	},

	computed: {
		blocks() {
			return (this.blockList ?? []).filter((block) => {
				if (!block || block?.content?.deactivated === true) {
					return false;
				}

				if (block?.alias && block?.content && block?.key) {
					return true;
				} else if (process.env.NODE_ENV === 'development') {
					console.warn(
						`[BlockList] ${block.alias} has missing or faulty data:`,
						block
					);
				}

				return false;
			});
		},
	},

	methods: {
		getBlockNameFromAlias,

		blockExists(alias) {
			return blockNames.includes(getBlockNameFromAlias(alias));
		},
	},
};
</script>

<style lang="postcss">
.c-block-list {
	@apply mt-lg mb-xl;
}

/** No margin between solid blocks */
:where(.c-block-list__block[class*='--solid']:first-child) {
	@apply mt-0;
}

:where(.c-block-list__block[class*='--solid']
		+ .c-block-list__block[class*='--solid']) {
	@apply mt-0;
}

.c-block-list__block[class*='--wide'] {
	@apply relative z-20;
}

@screen print {
	.c-block-list {
		@apply mb-0;
	}
}
</style>
