<template>
	<div class="c-page-intitution-hero">
		<div class="print:bg-transparent text-text">
			<div
				class="
					c-page-intitution-hero__main
					bg-primary
					relative
					flex flex-col
					>=1024:block
				"
			>
				<!-- Content -->
				<div
					:class="[
						'c-page-intitution-hero__content',
						'c-page-intitution-hero--image',
					]"
				>
					<slot name="before"></slot>
					<!-- Breadcrumb -->
					<div class="c-page-intitution-hero px-layout-margin pt-xs">
						<PageBreadcrumb
							v-if="breadcrumb"
							:data="breadcrumb"
							class="mb-layout-margin print:hidden"
						/>
						<div
							class="
								c-page-institution-hero__container
								flex
								items-end
								<768:flex-col <768:justify-start
								gap-layout-gutter
								justify-between
								pb-layout-gutter
							"
						>
							<div class="w-7col">
								<!-- Content -->
								<BaseH1 class="leading-button" v-html="title" />
								<div
									v-if="description && description.length"
									class="text-h3 mt-sm"
									v-html="description"
								></div>
							</div>
							<div
								v-if="image"
								class="
									c-page-intitution-hero__image
									w-logoWidth
								"
							>
								<img
									v-if="image"
									:alt="image.altText"
									:widths="[
										320,
										480,
										720,
										960,
										1200,
										1440,
										1920,
										1200 * 2,
										1440 * 2,
										1920 * 2,
									]"
									:src="image.cropUrl || image.url"
									:width="image.width"
									:height="image.height"
									class="w-logoWidth"
								/>
							</div>
						</div>
					</div>
					<hr class="text-primary" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageBreadcrumb from '~/components/shared/PageBreadcrumb';
export default {
	name: 'InstitutionPageHero',
	components: {
		PageBreadcrumb,
	},

	props: {
		title: {
			type: String,
			required: true,
		},
		image: {
			type: Object,
		},
		description: {
			type: String,
		},

		breadcrumb: {
			type: Array,
			required: false,
		},
	},
};
</script>

<style lang="postcss">
.c-page-intitution-hero__image .c-umbraco-image-ext {
	background-color: transparent !important;
}
.c-page-intitution-hero__image {
	width: 35%;
	height: auto;
	justify-self: baseline;
}
@screen >=1024 {
	.c-page-intitution-hero__image {
		justify-self: center;
	}
}
.c-page-intitution-hero {
	background-color: white !important;
}
.c-page-intitution-hero .c-page-breadcrumb {
	background-color: white !important;
}

/* For proper spacing */
.c-page-intitution-hero__content {
	padding-top: 144px !important;
	padding-top: calc(
		144px - var(--theme-spacing-xl--sm) +
			var(--theme-spacing-xl, var(--theme-spacing-xl--sm))
	) !important;
}

@screen >=1024 {
	.c-page-intitution-hero__content {
		padding-top: 186px !important;
		padding-top: calc(
			186px - var(--theme-spacing-xl--md) +
				var(--theme-spacing-xl, var(--theme-spacing-xl--sm))
		) !important;
	}
}

@screen >=1440 {
	.c-page-intitution-hero__content {
		padding-top: 232px !important;
		padding-top: calc(
			232px - var(--theme-spacing-xl--md) +
				var(--theme-spacing-xl, var(--theme-spacing-xl--sm))
		) !important;
	}
}

@screen print {
	.c-page-intitution-hero__content {
		padding-top: 0 !important;
	}
	.c-page-intitution-hero .c-page-intitution-hero__main,
	.c-page-intitution-hero .c-page-intitution-hero__main * {
		background-color: transparent !important;
	}
}
</style>
