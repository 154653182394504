var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-long-read-desktop',
		'transform w-fit max-w-full',
		'duration-200 ease-smooth-out' ],attrs:{"id":"c-long-read-desktop","tabindex":"-1"}},[_c('div',{class:[
			'c-long-read-desktop__header',
			'border-b border-primary-button h-md',
			'text-h8 text-primary-button uppercase' ],domProps:{"textContent":_vm._s("Sidens indhold:")}}),_vm._v(" "),_c('div',{ref:"scroller",class:[
			'c-long-read-desktop__content',
			'overflow-y-auto u-hide-scrollbar w-fit max-w-full pt-sm' ],on:{"scroll":_vm.onInternalScroll}},[(_vm.data.targets.length)?_c('ul',{ref:"inner",staticClass:"space-y-xs"},_vm._l((_vm.data.targets),function(target){return _c('li',{key:("target-" + (target.id))},[_c('a',{class:[
						'c-long-read__target',
						'block relative',
						'text-left font-semibold',
						'duration-300 ease-smooth-out',

						{
							'text-body pr-sm hover:text-primary-button':
								!_vm.data.activeTarget ||
								_vm.data.activeTarget.id !== target.id,
							'c-long-read__target--active text-primary-button pl-sm':
								_vm.data.activeTarget &&
								_vm.data.activeTarget.id === target.id,
						} ],attrs:{"href":("#" + (target.id)),"tabindex":_vm.hidden ? -1 : 0},domProps:{"textContent":_vm._s(target.title)},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.actions.scrollToTarget &&
							_vm.actions.scrollToTarget(target); }).apply(null, arguments)}}})])}),0):_vm._e()]),_vm._v(" "),_c('div',{class:[
			'c-long-read-desktop__gradient',
			'c-long-read-desktop__gradient--top',
			'absolute top-md z-50',
			'pointer-events-none w-full h-xl z-50',
			'duration-300 ease-smooth-out',

			{
				'opacity-0': !_vm.canScrollUp,
			} ]}),_vm._v(" "),_c('div',{class:[
			'c-long-read-desktop__gradient',
			'c-long-read-desktop__gradient--bottom',
			'absolute bottom-0 z-50',
			'pointer-events-none w-full h-xl',
			'duration-300 ease-smooth-out',

			{
				'opacity-0': !_vm.canScrollDown,
			} ]})])}
var staticRenderFns = []

export { render, staticRenderFns }