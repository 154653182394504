<template>
	<nav class="c-page-breadcrumb" aria-label="Brødkrumme">
		<template v-for="(item, index) in computedData">
			<NuxtLinkExt
				:key="`crumb-${item.id}`"
				:to="item.url"
				:target="item.target"
				:class="[
					'whitespace-nowrap mr-12 text-text',

					{
						'font-semibold pointer-events-none':
							index === computedData.length - 1,
					},
					{
						'no-underline hover:underline':
							index !== computedData.length - 1,
					},
				]"
				v-text="item.name"
			/>

			<SvgCaret
				v-if="index !== computedData.length - 1"
				:key="`seperator-${item.id}`"
				class="flex-shrink-0 w-10 inline mr-12"
			/>
		</template>
	</nav>
</template>

<script>
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';

export default {
	name: 'PageBreadcrumb',
	components: { SvgCaret },

	props: {
		data: {
			type: Array,
			required: true,
		},
	},

	computed: {
		computedData() {
			const data = this.data.concat([]);
			data[0].name = 'Forside';
			return data;
		},
	},
};
</script>
