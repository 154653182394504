import { camelCase, upperFirst } from 'lodash';

const BLOCKS_BY_SOLUTION_AND_ALIAS = {
	// Plop: block-list - add solution
	main: {
		// Plop: block-list - add main block
		accordionAgendaItem: () =>
			import(
				/* webpackChunkName: "main__blockListAccordionAgendaItem" */ '~/components/main/BlockList/blocks/BlockListAccordionAgendaItemCustom.vue'
			),
		accordionAgenda: () =>
			import(
				/* webpackChunkName: "main__blockListAgendaAccordion" */ '~/components/main/BlockList/blocks/BlockListAgendaAccordionCustom.vue'
			),
		BlockBorgerDkText: () =>
			import(
				/* webpackChunkName: "main__blockListBorgerDkText" */ '~/components/shared/BlockList/blocks/BlockListBorgerDkTextCustom.vue'
			),
		BlockBorgerDkMicroArticle: () =>
			import(
				/* webpackChunkName: "main__blockListBorgerMicroArticle" */ '~/components/shared/BlockList/blocks/BlockListBorgerMicroArticleCustom.vue'
			),
		BlockAccordionSharedItems: () =>
			import(
				/* webpackChunkName: "main__blockListAccordionSharedItems" */ '~/components/shared/BlockList/blocks/BlockListAccordionSharedItemsCustom.vue'
			),
		BlockVisualShortcuts: () =>
			import(
				/* webpackChunkName: "main__blockListVisualShortcuts" */ '~/components/shared/BlockList/blocks/BlockListVisualShortcutsCustom.vue'
			),
		BlockSpotBoxContainer: () =>
			import(
				/* webpackChunkName: "main__blockListSpotBoxContainer" */ '~/components/shared/BlockList/blocks/BlockListSpotBoxContainerCustom.vue'
			),
		BlockShortcuts: () =>
			import(
				/* webpackChunkName: "main__blockListShortcuts" */ '~/components/main/BlockList/blocks/BlockListShortcutsCustom.vue'
			),
		BlockForm: () =>
			import(
				/* webpackChunkName: "main__blockListForm" */ '~/components/shared/BlockList/blocks/BlockListFormCustom.vue'
			),
		BlockKultuNaut: () =>
			import(
				/* webpackChunkName: "main__blockListKultuNaut" */ '~/components/shared/BlockList/blocks/BlockListKultuNautCustom.vue'
			),
		BlockNewsList: () =>
			import(
				/* webpackChunkName: "main__blockListNewsList" */ '~/components/shared/BlockList/blocks/BlockListNewsListCustom.vue'
			),
		BlockIframe: () =>
			import(
				/* webpackChunkName: "main__blockListIframe" */ '~/components/shared/BlockList/blocks/BlockListIframeCustom.vue'
			),
		BlockVideo: () =>
			import(
				/* webpackChunkName: "main__blockListVideo" */ '~/components/shared/BlockList/blocks/BlockListVideoCustom.vue'
			),
		BlockTextMedia: () =>
			import(
				/* webpackChunkName: "main__blockListTextMedia" */ '~/components/shared/BlockList/blocks/BlockListTextMediaCustom.vue'
			),
		BlockContactGroup: () =>
			import(
				/* webpackChunkName: "main__blockListContactGroup" */ '~/components/shared/BlockList/blocks/BlockListContactGroupCustom.vue'
			),
		BlockStructuredData: () =>
			import(
				/* webpackChunkName: "main__blockListStructuredData" */ '~/components/shared/BlockList/blocks/BlockListStructuredDataCustom.vue'
			),
		BlockImages: () =>
			import(
				/* webpackChunkName: "main__blockListImages" */ '~/components/shared/BlockList/blocks/BlockListImagesCustom.vue'
			),
		BlockContactPerson: () =>
			import(
				/* webpackChunkName: "main__blockListContactPerson" */ '~/components/shared/BlockList/blocks/BlockListContactPersonCustom.vue'
			),
		BlockBorgerDk: () =>
			import(
				/* webpackChunkName: "main__blockListBorgerDk" */ '~/components/shared/BlockList/blocks/BlockListBorgerDkCustom.vue'
			),
		BlockAccordion: () =>
			import(
				/* webpackChunkName: "main__blockListAccordion" */ '~/components/shared/BlockList/blocks/BlockListAccordionCustom.vue'
			),
		BlockCtaBox: () =>
			import(
				/* webpackChunkName: "main__blockListCtaBox" */ '~/components/shared/BlockList/blocks/BlockListCtaBoxCustom.vue'
			),
		BlockCtaButton: () =>
			import(
				/* webpackChunkName: "main__blockListCtaButton" */ '~/components/shared/BlockList/blocks/BlockListCtaButtonCustom.vue'
			),
		BlockMap: () =>
			import(
				/* webpackChunkName: "main__blockListMap" */ '~/components/shared/BlockList/blocks/BlockListMapCustom.vue'
			),
		BlockLinkList: () =>
			import(
				/* webpackChunkName: "main__blockListLinkList" */ '~/components/shared/BlockList/blocks/BlockListLinkListCustom.vue'
			),
		BlockDocumentList: () =>
			import(
				/* webpackChunkName: "main__blockListDocumentList" */ '~/components/shared/BlockList/blocks/BlockListDocumentListCustom.vue'
			),
		BlockImageGallery: () =>
			import(
				/* webpackChunkName: "main__blockListImages" */ '~/components/shared/BlockList/blocks/BlockListImageGalleryCustom.vue'
			),
		BlockSelfService: () =>
			import(
				/* webpackChunkName: "main__blockListSelfService" */ '~/components/main/BlockList/blocks/BlockListSelfServiceCustom.vue'
			),
		BlockFactBox: () =>
			import(
				/* webpackChunkName: "main__blockListFactBox" */ '~/components/shared/BlockList/blocks/BlockListFactBoxCustom.vue'
			),
		BlockRichTextEditor: () =>
			import(
				/* webpackChunkName: "main__blockListRte" */ '~/components/shared/BlockList/blocks/BlockListRteCustom.vue'
			),
		BlockRichTextEditorShared: () =>
			import(
				/* webpackChunkName: "main__blockListRte" */ '~/components/shared/BlockList/blocks/BlockListRteSharedCustom.vue'
			),
		BlockQuote: () =>
			import(
				/* webpackChunkName: "main__blockListBlockQuote" */ '~/components/shared/BlockList/blocks/BlockListQuoteCustom.vue'
			),
		BlockInstitutionalNewsList: () =>
			import(
				/* webpackChunkName: "main__blockListBlockInstitutionalNewsList" */ '~/components/shared/BlockList/blocks/BlockListNewsListCustom.vue'
			),
	},
	subsite: {
		// Plop: block-list - add subsite block
		BlockShortcuts: () =>
			import(
				/* webpackChunkName: "subsite__blockListShortcuts" */ '~/components/subsite/BlockList/blocks/BlockListShortcutsCustom.vue'
			),
		BlockSubSiteNewsList: () =>
			import(
				/* webpackChunkName: "subsite__blockListNewsList" */ '~/components/shared/BlockList/blocks/BlockListNewsListCustom.vue'
			),
		BlockVisualShortcuts: () =>
			import(
				/* webpackChunkName: "subsite__blockListVisualShortcuts" */ '~/components/shared/BlockList/blocks/BlockListVisualShortcutsCustom.vue'
			),
		BlockSubsiteSpotBoxContainer: () =>
			import(
				/* webpackChunkName: "subsite__blockListSpotBoxContainer" */ '~/components/shared/BlockList/blocks/BlockListSpotBoxContainerCustom.vue'
			),
		BlockVideo: () =>
			import(
				/* webpackChunkName: "subsite__blockListVideo" */ '~/components/shared/BlockList/blocks/BlockListVideoCustom.vue'
			),
		BlockSubsiteKultuNaut: () =>
			import(
				/* webpackChunkName: "subsite__blockListKultuNaut" */ '~/components/shared/BlockList/blocks/BlockListKultuNautCustom.vue'
			),
		BlockRichTextEditor: () =>
			import(
				/* webpackChunkName: "subsite__blockListRte" */ '~/components/shared/BlockList/blocks/BlockListRteCustom.vue'
			),
		BlockTextMedia: () =>
			import(
				/* webpackChunkName: "subsite__blockListTextMedia" */ '~/components/shared/BlockList/blocks/BlockListTextMediaCustom.vue'
			),
		BlockImages: () =>
			import(
				/* webpackChunkName: "subsite__blockListImages" */ '~/components/shared/BlockList/blocks/BlockListImagesCustom.vue'
			),
		BlockQuote: () =>
			import(
				/* webpackChunkName: "subsite__blockListBlockQuote" */ '~/components/shared/BlockList/blocks/BlockListQuoteCustom.vue'
			),
		BlockFactBox: () =>
			import(
				/* webpackChunkName: "subsite__blockListFactBox" */ '~/components/shared/BlockList/blocks/BlockListFactBoxCustom.vue'
			),
		BlockImageGallery: () =>
			import(
				/* webpackChunkName: "subsite__blockListImages" */ '~/components/shared/BlockList/blocks/BlockListImageGalleryCustom.vue'
			),
		BlockBorgerDk: () =>
			import(
				/* webpackChunkName: "subsite__blockListBorgerDk" */ '~/components/shared/BlockList/blocks/BlockListBorgerDkCustom.vue'
			),
		BlockBorgerDkText: () =>
			import(
				/* webpackChunkName: "subsite__blockListBorgerDkText" */ '~/components/shared/BlockList/blocks/BlockListBorgerDkTextCustom.vue'
			),
		BlockBorgerDkMicroArticle: () =>
			import(
				/* webpackChunkName: "subsite__blockListBorgerMicroArticle" */ '~/components/shared/BlockList/blocks/BlockListBorgerMicroArticleCustom.vue'
			),
		BlockStructuredData: () =>
			import(
				/* webpackChunkName: "subsite__blockListStructuredData" */ '~/components/shared/BlockList/blocks/BlockListStructuredDataCustom.vue'
			),
		BlockLinkList: () =>
			import(
				/* webpackChunkName: "subsite__blockListLinkList" */ '~/components/shared/BlockList/blocks/BlockListLinkListCustom.vue'
			),
		BlockDocumentList: () =>
			import(
				/* webpackChunkName: "subsite__blockListDocumentList" */ '~/components/shared/BlockList/blocks/BlockListDocumentListCustom.vue'
			),
		BlockSubsiteMap: () =>
			import(
				/* webpackChunkName: "subsite__blockListMap" */ '~/components/shared/BlockList/blocks/BlockListMapCustom.vue'
			),
		BlockSubsiteContactPerson: () =>
			import(
				/* webpackChunkName: "subsite__blockListContactPerson" */ '~/components/shared/BlockList/blocks/BlockListContactPersonCustom.vue'
			),
		BlockSubsiteContactGroup: () =>
			import(
				/* webpackChunkName: "subsite__blockListContactGroup" */ '~/components/shared/BlockList/blocks/BlockListContactGroupCustom.vue'
			),
		BlockCtaButton: () =>
			import(
				/* webpackChunkName: "subsite__blockListCtaButton" */ '~/components/shared/BlockList/blocks/BlockListCtaButtonCustom.vue'
			),
		BlockCtaBox: () =>
			import(
				/* webpackChunkName: "subsite__blockListCtaBox" */ '~/components/shared/BlockList/blocks/BlockListCtaBoxCustom.vue'
			),
		BlockForm: () =>
			import(
				/* webpackChunkName: "subsite__blockListForm" */ '~/components/shared/BlockList/blocks/BlockListFormCustom.vue'
			),
		BlockIframe: () =>
			import(
				/* webpackChunkName: "subsite__blockListIframe" */ '~/components/shared/BlockList/blocks/BlockListIframeCustom.vue'
			),
		BlockSubSiteAccordion: () =>
			import(
				/* webpackChunkName: "subsite__blockListAccordion" */ '~/components/shared/BlockList/blocks/BlockListAccordionCustom.vue'
			),
		BlockAccordionSharedItems: () =>
			import(
				/* webpackChunkName: "subsite__blockListAccordionSharedItems" */ '~/components/shared/BlockList/blocks/BlockListAccordionSharedItemsCustom.vue'
			),
		BlockRichTextEditorShared: () =>
			import(
				/* webpackChunkName: "subsite__blockListRte" */ '~/components/shared/BlockList/blocks/BlockListRteSharedCustom.vue'
			),
	},
};

export const getBlockNameFromAlias = (alias) =>
	`Block${upperFirst(camelCase(alias))}`;

export const getBlocksBySolution = (solution) =>
	Object.entries(BLOCKS_BY_SOLUTION_AND_ALIAS[solution] ?? {}).reduce(
		(acc, [alias, component]) => ({
			...acc,

			[getBlockNameFromAlias(alias)]: component,
		}),
		{}
	);
