<template>
	<aside
		:class="[
			'c-page-aside pointer-events-none',
			{ '<1024:pt-78': !$scopedSlots.content },
		]"
	>
		<div
			:class="[
				'>=1024:absolute >=1024:left-0 >=1024:top-0',
				'>=1024:w-2col >=1024:h-full z-20',
				{ 'absolute left-0 top-0 w-full h-full': mobileSticky },
			]"
		>
			<div v-if="$scopedSlots.default" class="mb-sm pointer-events-auto">
				<slot></slot>
			</div>

			<div
				ref="content"
				:class="[
					'>=1024:sticky >=1024:top-lg transform',
					'duration-200 ease-smooth-out',
					{ 'opacity-5 scale-95 pointer-events-none': hidden },
					{ 'sticky top-0': mobileSticky },
				]"
				:aria-hidden="hidden"
			>
				<slot name="content">
					<LongReadController v-slot="{ data, actions }">
						<LongReadDesktop
							v-if="data.targets.length"
							v-bind="{ data, actions, hidden }"
							class="hidden >=1024:block pointer-events-auto"
						/>
					</LongReadController>
				</slot>
			</div>
		</div>
	</aside>
</template>

<script>
import LongReadDesktop from './LongReadDesktop.vue';

import LongReadController from '~/citi-baseline/components/LongReadController.vue';
export default {
	name: 'PageAside',
	components: { LongReadController, LongReadDesktop },

	props: {
		mobileSticky: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			hidden: false,
		};
	},

	mounted() {
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onScroll);
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onScroll);
	},

	methods: {
		onScroll() {
			const obstacles = document.querySelectorAll(
				'*[class*="c-block-list-"][class*="--wide"]'
			);

			if (
				obstacles?.length &&
				this.$refs?.content &&
				window.innerWidth >= 1024
			) {
				this.hidden = Array.from(obstacles).reduce((acc, el) => {
					return (
						acc ||
						this.percentageOverlap(el, this.$refs.content, 96) === 0
					);
				}, false);
			} else {
				this.hidden = false;
			}
		},

		/** Helper Functions: */
		percentageOverlap(first, second, margin = 0) {
			const { height: fh, top: fy } = first.getBoundingClientRect();

			const { height: sh, top: sy } = second.getBoundingClientRect();

			const top = Math.max(fy - margin, sy);
			const bottom = Math.min(fy + fh + margin, sy + sh);

			const ip = (top - bottom) / fh;
			return Math.max(Math.min(ip, 1), 0);
		},
	},
};
</script>
