var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-page-intitution-hero"},[_c('div',{staticClass:"print:bg-transparent text-text"},[_c('div',{staticClass:"\n\t\t\t\tc-page-intitution-hero__main\n\t\t\t\tbg-primary\n\t\t\t\trelative\n\t\t\t\tflex flex-col\n\t\t\t\t>=1024:block\n\t\t\t"},[_c('div',{class:[
					'c-page-intitution-hero__content',
					'c-page-intitution-hero--image' ]},[_vm._t("before"),_vm._v(" "),_c('div',{staticClass:"c-page-intitution-hero px-layout-margin pt-xs"},[(_vm.breadcrumb)?_c('PageBreadcrumb',{staticClass:"mb-layout-margin print:hidden",attrs:{"data":_vm.breadcrumb}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tc-page-institution-hero__container\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\titems-end\n\t\t\t\t\t\t\t<768:flex-col <768:justify-start\n\t\t\t\t\t\t\tgap-layout-gutter\n\t\t\t\t\t\t\tjustify-between\n\t\t\t\t\t\t\tpb-layout-gutter\n\t\t\t\t\t\t"},[_c('div',{staticClass:"w-7col"},[_c('BaseH1',{staticClass:"leading-button",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),(_vm.description && _vm.description.length)?_c('div',{staticClass:"text-h3 mt-sm",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()],1),_vm._v(" "),(_vm.image)?_c('div',{staticClass:"\n\t\t\t\t\t\t\t\tc-page-intitution-hero__image\n\t\t\t\t\t\t\t\tw-logoWidth\n\t\t\t\t\t\t\t"},[(_vm.image)?_c('img',{staticClass:"w-logoWidth",attrs:{"alt":_vm.image.altText,"widths":[
									320,
									480,
									720,
									960,
									1200,
									1440,
									1920,
									1200 * 2,
									1440 * 2,
									1920 * 2 ],"src":_vm.image.cropUrl || _vm.image.url,"width":_vm.image.width,"height":_vm.image.height}}):_vm._e()]):_vm._e()])],1),_vm._v(" "),_c('hr',{staticClass:"text-primary"})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }