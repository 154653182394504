var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"d-institution-page-custom"},[_c('LimboSearch',{staticClass:"relative",attrs:{"search-filters":_vm.filters,"config":{
			allowSearchFiltersMutation: true,
			enableLiveSearch: true,
			searchResponseTransformerMethod: _vm.searchResponseTransformerMethod,
		}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
		var data = ref.data;
		var state = ref.state;
return [_c('InstitutionPageHero',_vm._b({attrs:{"title":data ? data.title : _vm.title,"description":data ? data.headerDescription : _vm.headerDescription,"image":data ? data.headerImage : _vm.headerImage},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('div',{staticClass:"grid px-layout-margin"},[_c('div',{class:[
							'flex flex-col w-full gap-layout-gutter bg-primary  >=1024:flex-row',
							{
								'mb-sm': !_vm.overviewUrl,
							} ]},[_c('BaseInputDropdown',{staticClass:"w-full",attrs:{"value":_vm.computedFilterValues.location,"config":_vm.computedFilters.location,"aria-label":_vm.computedFilters.location.placeholder},on:{"input":function (event) { return (
									_vm.updateValue('location', event),
									_vm.updateInstitutions(event)
								); }}}),_vm._v(" "),_c('BaseInputDropdown',{staticClass:"w-full",attrs:{"value":_vm.computedFilterValues.institution,"config":_vm.computedFilters.institution,"aria-label":_vm.computedFilters.institution.placeholder},on:{"input":function (event) { return _vm.updateValue('institution', event); }}})],1),_vm._v(" "),(_vm.overviewUrl && _vm.overviewUrlTitle)?_c('NuxtLinkExt',{staticClass:"inline-block my-sm w-fit justify-self-end",attrs:{"to":_vm.overviewUrl,"target":_vm.overviewUrl}},[_c('div',{class:'flex gap-xs items-center group'},[_c('span',{staticClass:"\n\t\t\t\t\t\t\t\t\ttext-primary-button\n\t\t\t\t\t\t\t\t\tfont-semibold\n\t\t\t\t\t\t\t\t\tuppercase\n\t\t\t\t\t\t\t\t"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.overviewUrlTitle)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('BaseButton',{attrs:{"tag":"button"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{class:[
											'duration-200 ease-smooth-out',
											'group-hover:translate-y-2' ]})]},proxy:true}],null,true)})],1)]):_vm._e()],1)]},proxy:true}],null,true)},'InstitutionPageHero',{ breadcrumb: _vm.breadcrumb },false)),_vm._v(" "),_c('div',[(state.isLoading)?_c('div',{key:"loading"},[_c('SearchLoader',{staticClass:"w-md mx-auto my-layout-gutter"})],1):_vm._e(),_vm._v(" "),(data && data.length > 0)?_c('div',_vm._l((data),function(item){return _c('div',{key:item.id},[_vm._v("\n\t\t\t\t\t"+_vm._s(item.location)+"\n\t\t\t\t")])}),0):_vm._e()]),_vm._v(" "),(data && data.contentElements && !state.isLoading)?[_c('div',{staticClass:"mx-layout-margin relative mb-lg"},[(data.contentElements && data.showToc)?_c('PageAside',{staticClass:"<1024:hidden",attrs:{"show-longread":data.showToc}}):_vm._e(),_vm._v(" "),_c('BlockList',{attrs:{"block-list":data.contentElements}})],1)]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }