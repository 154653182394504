<!--
	Last modified: 2024/06/18 12:41:04
-->
<template>
	<div class="c-long-read-controller">
		<slot v-bind="slotBindings"></slot>
	</div>
</template>

<script>
import Vue from 'vue';

/** Data */
export const data = Vue.observable({
	_targets: {},

	get targets() {
		const keys = Object.keys(this._targets);
		keys.sort((a, b) => {
			const aOrder = this._targets[a].order;
			const bOrder = this._targets[b].order;

			for (let n = 0; n < Math.min(aOrder?.length, bOrder?.length); n++) {
				if (aOrder[n] !== bOrder[n]) {
					return (aOrder[n] > bOrder[n]) * 2 - 1;
				}
			}

			return 0;
		});

		return keys.map((e) => this._targets[e]);
	},

	get activeTarget() {
		return [...this.targets].reverse().reduce((acc, target) => {
			return target.visibility >= acc?.visibility ? target : acc;
		}, this.targets[0]);
	},
});

/** Component */
export default {
	name: 'LongReadController',

	data() {
		return {
			targetElements: [],
			intersectionObserver: null,
		};
	},

	computed: {
		slotBindings() {
			return {
				data: {
					targets: data.targets,
					activeTarget: data.activeTarget,
				},

				actions: {
					scrollToTarget: this.scrollToTarget,
				},
			};
		},
	},

	mounted() {
		this.onScroll();
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onScroll);

		this.intersectionObserver = new IntersectionObserver(
			this.onIntersection,
			{ threshold: 0 }
		);

		this.$watch(
			() => data.targets,
			(value) => {
				this.targetElements = [
					...(value || []).map(({ id }) =>
						document.getElementById(id)
					),
				];

				this.intersectionObserver.disconnect();
				this.targetElements.forEach((target) => {
					target && this.intersectionObserver.observe(target);
				});
			}
		);
	},

	beforeDestroy() {
		this.intersectionObserver.disconnect();
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onScroll);
	},

	methods: {
		/** Events */
		onScroll() {
			this.targetElements
				.filter((target) => data._targets[target.id]?.inViewport)
				.forEach((target) => {
					const percentage = this.percentageInViewport(target);
					Object.assign(data._targets[target.id], {
						visibility: percentage,
					});
				});
		},

		onIntersection(entries) {
			entries.forEach(({ isIntersecting, target }) => {
				data._targets[target.id] &&
					(data._targets[target.id].inViewport = isIntersecting);
			});
		},

		/** Actions */
		scrollToTarget(target) {
			history.replaceState({}, '', `#${target.id}`);
			document.getElementById(target.id)?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		},

		/** Helper functions */
		percentageInViewport(element) {
			const { height, width, left, top } =
				element.getBoundingClientRect();

			const l = Math.max(0, left);
			const t = Math.max(0, top);
			const r = Math.min(window.innerWidth, left + width);
			const b = Math.min(window.innerHeight, top + height);

			const ip = ((l - r) * (t - b)) / (width * height);
			return Math.max(Math.min(ip, 1), 0);
		},
	},
};
</script>
