var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"c-page-breadcrumb",attrs:{"aria-label":"Brødkrumme"}},[_vm._l((_vm.computedData),function(item,index){return [_c('NuxtLinkExt',{key:("crumb-" + (item.id)),class:[
				'whitespace-nowrap mr-12 text-text',

				{
					'font-semibold pointer-events-none':
						index === _vm.computedData.length - 1,
				},
				{
					'no-underline hover:underline':
						index !== _vm.computedData.length - 1,
				} ],attrs:{"to":item.url,"target":item.target},domProps:{"textContent":_vm._s(item.name)}}),_vm._v(" "),(index !== _vm.computedData.length - 1)?_c('SvgCaret',{key:("seperator-" + (item.id)),staticClass:"flex-shrink-0 w-10 inline mr-12"}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }