<template>
	<div class="c-block-list-placeholder">
		<span>[block] {{ alias }}</span>

		<pre v-if="content" class="c-block-list-placeholder__content">{{
			content
		}}</pre>
	</div>
</template>

<script>
export default {
	name: 'BlockListPlaceholder',

	props: {
		alias: String,
		content: Object,
	},
};
</script>

<style>
.c-block-list-placeholder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 1920px;
	margin: 20px auto;
	padding: 5% 20%;
	color: black;
	font-family: monospace;
	background: white;
	border: 1px dashed black;
}

.c-block-list-placeholder__content {
	width: 100%;
	max-width: 800px;
	max-height: 240px;
	overflow: scroll;
	background-color: rgb(235, 235, 235);
	border: 1px dashed rgb(115, 115, 115);
}
</style>
