var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{class:[
		'c-page-aside pointer-events-none',
		{ '<1024:pt-78': !_vm.$scopedSlots.content } ]},[_c('div',{class:[
			'>=1024:absolute >=1024:left-0 >=1024:top-0',
			'>=1024:w-2col >=1024:h-full z-20',
			{ 'absolute left-0 top-0 w-full h-full': _vm.mobileSticky } ]},[(_vm.$scopedSlots.default)?_c('div',{staticClass:"mb-sm pointer-events-auto"},[_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"content",class:[
				'>=1024:sticky >=1024:top-lg transform',
				'duration-200 ease-smooth-out',
				{ 'opacity-5 scale-95 pointer-events-none': _vm.hidden },
				{ 'sticky top-0': _vm.mobileSticky } ],attrs:{"aria-hidden":_vm.hidden}},[_vm._t("content",function(){return [_c('LongReadController',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var data = ref.data;
			var actions = ref.actions;
return [(data.targets.length)?_c('LongReadDesktop',_vm._b({staticClass:"hidden >=1024:block pointer-events-auto"},'LongReadDesktop',{ data: data, actions: actions, hidden: _vm.hidden },false)):_vm._e()]}}])})]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }